import React from "react"
import { Container } from "react-bootstrap"
import styles from "../../scss/components/Festejomundial/_Main.module.scss"

export default function Main({registrations, canRegister}) {
  return (
    <div className={styles.container}>
      <div className={styles.textsContainer}>
          {registrations < 7500 && canRegister ?
              <>
                  <h1 className={styles.firstText}>
                      SUMATE AL FESTEJO MUNDIALISTA de BUDWEISER. <br /> MESSI TRAJO LA COPA
                      <br /> Y LAS BUD PARA FESTEJAR.
                  </h1>
                  <h2 className={styles.secondText}>
                      Solo tenés que completar el formulario y ya quedás registrado para
                      vivirlo en Plaza de la Música, este lunes desde las 19:30.
                  </h2>
              </> :
              <>
                  <h1 className={styles.firstText}>
                      EVENTO FINALIZADO
                  </h1>
              </>
          }
      </div>
    </div>
  )
}
