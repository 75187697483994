import Cookies from "js-cookie"
import React, { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router"
// import { Link } from "react-router-dom"
import styles from "../../scss/components/Festejomundial/_Form.module.scss"
import CustomCheckBox from "./CustomCheckBox"
import Success from "./Success"
import localidades from "./proviciasylocalidades.json"
import provincias from "./provincias.json"
import { validateEmail, formatDate } from "../../utils/functions"

export default function Form({setStep}) {
  let history = useHistory()
  const [data, setData] = useState({
    name: "",
    dateofbirth: "",
    email: "",
    city: "",
    state: "",
  })
  // FIRST QUESTIONS
  const [selectedInput, setSelectedInput] = useState("")

  // TERMS BUTTON
  const [isChecked, setIsChecked] = useState(false)
  const [validateError, setValidateError] = useState("")
  const [cities, setCities] = useState([])

  useEffect(() => {
    const localidadesArr = Object.entries(localidades)
    if (data.state) {
      const filter = localidadesArr.filter(([key, value]) => {
        return key === data.state
      })
      const justStrings = Object.fromEntries(filter)
      setCities(justStrings[data.state])
    }
  }, [data.state])

  const handleChange = inputValue => {
    setSelectedInput(inputValue)
    setStep("6")
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setData(data => ({ ...data, [name]: value }))
  }

  function validateForm() {
    if (validateEmail(data)) {
      if (
        !data.name ||
        !data.state ||
        !data.dateofbirth ||
        !data.city ||
        !selectedInput
      ) {
        // ACA SI HAY ALGUN CAMPO VACIO
        setValidateError("empty")
      } else if (isChecked) {
        // ACA SI TODO ESTA BIEN
        setValidateError("success")

        let gamingAsoc = ""

        if (selectedInput === "Cocinando/ comiendo solo o acompañado") {
          gamingAsoc = "Cocinando/ comiendo solo o acompañado"
        } else if (
          selectedInput ===
          "Cuando me quiero relajar, escuchando música o usando RRSS"
        ) {
          gamingAsoc =
            "Cuando me quiero relajar, escuchando música o usando RRSS"
        } else if (selectedInput === "Con amigos en un bar o en una casa") {
          gamingAsoc = "Con amigos en un bar o en una casa"
        } else if (selectedInput === "Antes o durante una fiesta o evento") {
          gamingAsoc = "Antes o durante una fiesta o evento"
        } else if (
          selectedInput ===
          "Trasladándome a algún lugar o mientras estoy en mi casa"
        ) {
          gamingAsoc = "Trasladándome a algún lugar o mientras estoy en mi casa"
        } else if (selectedInput === "No tomo cerveza") {
          gamingAsoc = "No tomo cerveza"
        }

        let user = {
          fullname: data.name,
          dateofbirth: data.dateofbirth,
          email: data.email,
          state: data.state,
          city:data.city,
          preferenceQuestion: gamingAsoc,
          td: Cookies.get("_td"),
        }

        fetch("https://wabudweiser-prd.azurewebsites.net/festejomundial", {
        // fetch("http://localhost:8080/festejomundial", {
          method: "POST",
          body: JSON.stringify(user),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(response => {
          if (response.ok) {
            history.push("/festejomundial/success")
          } else {
            setValidateError("duplicated")
            response.text().then(text => {
              console.error("Error guardando los datos. \n", text)
            })
          }
        })
      } else {
        // ACA SI FALTA ACEPTAR LOS TERMINOS Y CONDICIONES
        setValidateError("terms")
      }
    } else {
      // ACA SI EL EMAIL ES INVALIDO
      setValidateError("email")
    }
  }


  const MessageValidate = () => {
    switch (validateError) {
      case "email":
        return <h1 className={styles.error}>EL EMAIL ES INVALIDO</h1>
      case "empty":
        return <h1 className={styles.error}>DEBE COMPLETAR TODOS LOS CAMPOS</h1>
      case "duplicated":
        return (
          <h1 className={styles.error}>
            El email ingresado ya está en uso
          </h1>
        )
      case "terms":
        return (
          <h1 className={styles.error}>
            DEBE ACEPTAR LOS TERMINOS Y CONDICIONES
          </h1>
        )
      case "success":
        return null
      default:
        return null
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    validateForm()
  }
  const CheckboxInput = ({ name, label, isChecked, setIsChecked,setStep }) => {
    const toggleCheck = e => {
      setIsChecked(() => !isChecked)
      setStep("8")
    }
    return (
      <div>
        <input
          type="checkbox"
          className={styles.termsRadio}
          name={name}
          id={name}
          checked={isChecked}
          onChange={toggleCheck}
        />
        <label htmlFor={name}>{/* <span>{label}</span> */}</label>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <input
          type="text"
          name="name"
          onFocus={()=>{setStep("1")}}
          onChange={handleInputChange}
          className={styles.simpleInput}
          placeholder="Nombre y Apellido"
        />
        <input
          type="text"
          name="dateofbirth"
          onChange={({ target }) => {
            const onlyNums = target.value.replace(/[^0-9]/g, "")
            const date = formatDate(onlyNums)
            setData({ ...data, dateofbirth: date })
          }}
          value={data.dateofbirth}
          className={styles.simpleInput}
          placeholder="Fecha de Nacimiento"
          onFocus={()=>{setStep("2")}}
        />
        <input
          type="email"
          className={styles.simpleInput}
          placeholder="Email"
          onChange={handleInputChange}
          name="email"
          onFocus={()=>{setStep("3")}}
        />
        <select
          className={styles.simpleInput}
          onChange={handleInputChange}
          value={data.state}
          onFocus={()=>{setStep("4")}}
          name="state"
        >
          <option selected hidden>
            Provincia
          </option>
          {provincias.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>

        <input
          list="cities"
          type="city"
          className={styles.simpleInput}
          placeholder="Localidad"
          onChange={handleInputChange}
          onFocus={()=>{setStep("5")}}
          name="city"
        />
        <datalist id="cities">
          {cities?.map(city => {
            console.log("city", city)
            return <option key={city} value={city} />
          })}
        </datalist>
        {/* A COMPONENTE */}

        {/* FIN DE A COMPONENTE */}
        <br />
        {/* CHECKBOX SECTION START */}
        <CustomCheckBox
          isFirstQ
          selectedInput={selectedInput}
          setSelectedInput={selectedInput}
          handleChange={handleChange}
        />

        <div className={styles.divider} />
        {/* TERMS START */}
        <div className={styles.termsContainer}>
          <CheckboxInput
            name="remember-me"
            label="Remember Me"
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            setStep={setStep}
          />
          <h4 className={styles.termsText}>
            He leído y acepto&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/tyc_festejomundial.pdf"
            >
              Términos y Condiciones
            </a>
            ,&nbsp;
            <a
                className={styles.link}
                target="_blank"
                href="/byc-festejomundial.pdf"
            >
              Bases y Condiciones
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/privacidad_festejomundial.pdf"
            >
              Políticas de Privacidad
            </a>
            &nbsp;y activaciones de marketing.
          </h4>
        </div>
        {/* TERMS END */}
        {/* SUBMIT START */}
        <div style={{ paddingBottom: "20px", alignSelf: "center" }}>
          <input type="submit" value="Enviar" className={styles.btnSubmit} />
        </div>
        <MessageValidate />
      </form>
    </div>
  )
}
