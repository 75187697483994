import React from "react"
import SEO from "../seo"
import Footer from "../Footer"
import styles from "./../../scss/pages/_festejomundial.module.scss"

import logo from "./../../images/Esports/logo-bud.svg"
import { Container } from "react-bootstrap"

export default function Success() {
  return (
    <div styles={{ overflow: "hidden" }}>
      <SEO title="La bandera en tus manos" />
      <Container fluid className="m-0 p-0">
        <section>
          <div className={styles.wrap}>
            <div className={styles.container}>
              <div>
                <img src={logo} className={styles.logo} />
              </div>
              <div className={styles.wrapText}>
                <div className={styles.titleContainer}>
                  <h1 className={styles.title}>¡Gracias por registrarte!</h1>
                </div>
                <h1 className={styles.infoTextOne}>
                  Ya estás en la lista para vivir el Festejo Mundialista con
                  nosotros. <br /> Los esperamos el 26/12 a las 19.30 en <br />  Coronel Olmedo,
                  esquina Costanera – Plaza de la Música.
                </h1>
              </div>
              <div>
                <h1 className={styles.infoTextTwo}>
                  Con tu ingreso te regalamos una Bud para brindar juntos.
                  <br />
                  <br />
                  ¡TE ESPERAMOS!
                </h1>
                <a
                  className={styles.btnContainer}
                  target="_blank"
                  href="https://www.instagram.com/budweiser_ar/"
                >
                  <div className={styles.followBtn}>Seguinos</div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.footer}>
          <Footer />
        </div>
      </Container>
    </div>
  )
}
