import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import SEO from "../components/seo"
import arrowDown from "./../images/icons/arrowDown.png"
import Footer from "../components/Footer"
import Main from "../components/FestejoMundial/Main"
import styles from "./../scss/pages/_esport.module.scss"
import Form from "../components/FestejoMundial/Form"
import Success from "../components/FestejoMundial/Success"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"

export default function Esports() {
  const [step,setStep]= useState("")
  const [age, setAge] = useState(true)
  const [registrations, setRegistrations] = useState(0);
  const [loading, setLoading] = useState(true);

  const canRegister = (new Date('2022/12/26 20:30')) < (new Date());

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Festejo Mundial",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/festejomundial",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: step ? `step_${step}` : "",
    })
  }, [step])


  useEffect(() => {
    // console.log("ok!")
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    // console.log("Cookie",cookie)
    if (cookie || local) setAge(false)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const registrations = await fetch("https://wabudweiser-prd.azurewebsites.net/festejomundial/list", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const registrationsData = await registrations.json();

      setRegistrations(registrationsData.length);
      setLoading(false);
    }
    fetchData()

  }, [])
  console.log("REGS", registrations);
  return !age ? (
    <Router>
      <Route exact path="/festejomundial">
        <div styles={{ overflow: "hidden" }}>
          <SEO title="Festejo Mundial" />
          <Container fluid className="m-0 p-0">
            <section>
              <Main registrations={registrations} canRegister={canRegister}/>
            </section>
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#FormSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  {!loading && registrations < 7500 && canRegister && <img src={arrowDown} className={styles.ArrowDownSection}/>}
                </a>
              </Col>
            </Row>
            <section id="FormSection">
              {!loading && registrations < 7500 && canRegister && <Form setStep={setStep} registrations={registrations}/>}
            </section>
            <Footer />
          </Container>
        </div>
      </Route>
      <Route path="/festejomundial/success">
        <Success />
      </Route>
    </Router>
  ) : (
    <AgeGate path="/festejomundial" />
  )
}
